import React from 'react';
import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { allMovementCellRender, getSAPLink, getSAPLinkFunction } from './components/helpers';

const consumptionHistoryFilter = (excludePM10) => (excludePM10
  ? (value) => value.WORK_ORDER_TYPE !== 'PM10' && value.EXCLUDE_REVISION_CODE === false
  : (value) => value.EXCLUDE_REVISION_CODE === false);

const getTabOptions = (plant, material, excludePM10 = false) => ({
  allMovementOptions: {
    EDAM_POSTING_DT: {
      defaultSortOrder: 'descend',
      filters: true,
      filterOn: 'YEAR',
    },
    GOODS_MOVEMENT_TYPE_SAP_ID: {
      filters: true,
    },
    WORK_ORDER_SAP_ID: {
      render: getSAPLinkFunction('Navigate to IW33 in SAP', 'IW33'),
    },
    MATERIAL_MOVEMENT_DOCUMENT_SAP_ID: {
      render: getSAPLinkFunction('Navigate to MB51 in SAP', 'MB51', material),
    },
    PURCHASE_ORDER_SAP_ID: {
      render: getSAPLinkFunction('Navigate to ME2N in SAP', 'ME2N'),
    },
    sensitiveValues: ['EDAM_LOCAL_CURRENCY_AMT'],
    MATERIAL_BASE_UOM_CALCULATED_QTY: {
      render: allMovementCellRender,
    },
    EDAM_LOCAL_CURRENCY_AMT: {
      render: allMovementCellRender,
    },
    BASE_UNIT_OF_MEASURE_SAP_ID: {
      render: allMovementCellRender,
    },
  },
  functionalLocationOptions: {
    FACILITY_SAP_ID: {
      render: getSAPLinkFunction('Navigate to functional location in SAP', 'IL03'),
    },
  },
  reservationsOptions: {
    ORDER_NUMBER: {
      render: getSAPLinkFunction('Navigate to IW33 in SAP', 'IW33'),
    },
  },

  openDocs: {
    OPEN_DOC_ID: {
      render: (val, record) => {
        const value = val?.replace(/^0+/, '');

        if (record.DOC_RANK === 1) {
          if (value?.includes('R') || value?.includes('N')) {
            return value.replace('R', '')?.replace('N', '');
          }
        }
        let type;
        switch (record.DOC_RANK) {
          case 1:
            type = 'IW33';
            break;

          case 2:
          case 5:
            type = 'ME5A';
            break;

          case 3:
          case 6:
            type = 'ME2N';
            break;

          case 7:
            type = 'O3O_RT03';
            break;

          default:
            return <>{value}</>;
        }
        const renderFunc = getSAPLinkFunction(
          `Navigate to ${type} in SAP`,
          type,
          record.MATERIAL_TYPE_SAP_ID,
        );

        return renderFunc(value);
      },
    },
    DOCUMENT_DATE: {
      filters: true,
      filterOn: 'YEAR',
      render: (val) => (val ? val?.split('T')[0]?.replaceAll('-', '.') : val),
    },
    hiddenColumns: ['DOC_RANK', 'MATERIAL_TYPE_SAP_ID'],
  },

  consumptionHistoryOptions: {
    rowClassName: (record) => {
      if ((record?.WORK_ORDER_TYPE === 'PM10' && excludePM10) || record.EXCLUDE_REVISION_CODE === true) {
        return 'excluded';
      }
      return '';
    },
    filter: consumptionHistoryFilter(excludePM10),
    EDAM_POSTING_DT: {
      defaultSortOrder: 'descend',
      filters: true,
      filterOn: 'YEAR',
    },
    GOODS_MOVEMENT_TYPE_SAP_ID: {
      filters: true,
    },
    SOURCE_CONS_PLANT_ID: {
      filters: true,
    },
    PLANNING_PLANT_ID: {
      filters: true,
    },
    WORK_ORDER_SAP_ID: {
      render: getSAPLinkFunction('Navigate to IW33 in SAP', 'IW33'),
      filters: true,
    },
    WORK_ORDER_TYPE: {
      filters: true,
    },
    FL_CHECK: {
      filters: true,
    },
    FL_CRITICALITY: {
      filters: true,
    },
    MATERIAL_MOVEMENT_DOCUMENT_SAP_ID: {
      render: getSAPLinkFunction('Navigate to MB51 in SAP', 'MB51', material),
    },
    PURCHASE_ORDER_SAP_ID: {
      render: getSAPLinkFunction('Navigate to ME2N in SAP', 'ME2N'),
    },
    hiddenColumns: ['PLANT_FACILITY_SAP_ID', 'EXCLUDE_REVISION_CODE'],
  },
  purchasingOptions: {
    EDAM_POSTING_DT: {
      defaultSortOrder: 'descend',
      filters: true,
      filterOn: 'YEAR',
    },
    WORK_ORDER_SAP_ID: {
      render: getSAPLinkFunction('Navigate to IW33 in SAP', 'IW33', null, true),
    },
    MATERIAL_MOVEMENT_DOCUMENT_SAP_ID: {
      render: getSAPLinkFunction('Navigate to MB51 in SAP', 'MB51', material),
    },
    PURCHASE_ORDER_SAP_ID: {
      render: getSAPLinkFunction('Navigate to ME2N in SAP', 'ME2N'),
    },
  },
  materialChangelogOptions: {
    UDATETIME: {
      defaultSortOrder: 'descend',
      filters: true,
      filterOn: 'YEAR',
    },
    MEDIUM_FIELD_LABEL: {
      filters: true,
    },
    CC_MRP_AREA_SAP_ID: {
      filters: true,
    },
    hiddenColumns: ['FNAME'],
  },
  iotaChangelogOptions: {
    hiddenColumns: ['CHANGED_BY_ID'],
  },
  tbdOptions: {
    UNKNOWN_COLUMN: {
      render: getSAPLinkFunction('UNKNOWN NAVIGATION', 'ME33K'),
    },
  },
  returnTabOptions: {
    ReturnsDocumentNumber: {
      render: getSAPLinkFunction('Navigate to O3O_RT03 in SAP', 'O3O_RT03', null, true),
    },
    Returnsdocumentdate: {
      defaultSortOrder: 'descend',
      filters: true,
      filterOn: 'YEAR',
    },
    LongText: {
      render: (v) => (v ? <Tooltip overlayInnerStyle={{ width: '500px' }} title={v}><InfoCircleOutlined /></Tooltip> : ''),
    },
    OrderNumber: {
      render: getSAPLinkFunction('Navigate to IW33 in SAP', 'IW33', null, true),
    },
    PurchasingDocumentNumber: {
      render: getSAPLinkFunction('Navigate to ME2N in SAP', 'ME2N'),
    },
  },
});

const hiddenColumns = [
  'key',
  'CREATED_BY',
  'MATERIAL_TYPE_SAP_ID',
  'PLANT_FACILITY_SAP_NM',
];

export { getTabOptions, hiddenColumns };
