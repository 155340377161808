export const expandTabs = [
  {
    value: 'AM',
    description: 'All Movements',
  },
  {
    value: 'RC',
    description: 'Real Consumption',
  },
  {
    value: 'MC',
    description: 'MRP Changelog',
  },
  {
    value: 'FL',
    description: 'Functional Location',
  },
  {
    value: 'OD',
    description: 'Open Docs',
  },
  {
    value: 'RES',
    description: 'Reservations',
  },
  {
    value: 'PUR',
    description: 'Purchasing',
  },
  {
    value: 'LT',
    description: 'Lead Time',
  },
  {
    value: 'WH',
    description: 'Warehouse',
  },
  {
    value: 'ICL',
    description: 'IOTA Changelog',
  },
  {
    value: 'xplant',
    description: 'Other Plants',
  },
  {
    value: 'RD',
    description: 'Returns',
  },
];

export const textTabs = [
  {
    value: 'material_memo',
    description: 'Material memo',
  },
  {
    value: 'POtext',
    description: 'PO Text',
  },
  {
    value: 'Pl_Ord_Txt',
    description: 'Planned Order Text',
  },
  {
    value: 'internal_comment',
    description: 'Internal comments',
  },
  {
    value: 'classification',
    description: 'Classification',
  },
  {
    value: 'IOTAComments',
    description: 'IOTA Comments',
  },
  {
    value: 'WarehouseComments',
    description: 'Whs Comments',
  },
];
