import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import Draggable from 'react-draggable';
import PropTypes from 'prop-types';

const DraggableModal = ({
  content, title, visible, onOk, onCancel, okText, cancelText, className, footer, style, centered, noFooter,
}) => {
  const [bounds, setBounds] = useState({
    left: 0, top: 0, bottom: 0, right: 0,
  });

  const draggleRef = React.createRef();

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  const defaultFooter = () => {
    const footerButtons = [];
    const okButton = <Button key="ok" type="primary" onClick={onOk}>{okText || 'Ok'}</Button>;
    const closeButton = <Button key="close" onClick={onCancel}>{cancelText || 'Close'}</Button>;
    if (!onOk) {
      footerButtons.push(closeButton);
    } else {
      footerButtons.push(okButton);
      footerButtons.push(closeButton);
    }
    return footerButtons;
  };

  return (
    <Modal
      title={(
        <div
          style={{
            width: '100%',
            cursor: 'move',
          }}
        >
          {title}
        </div>
      )}
      visible={visible}
      style={style}
      centered={centered}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      cancelText={cancelText}
      className={className}
      modalRender={(modal) => (
        <Draggable
          handle=".handle"
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      footer={footer || (!noFooter && defaultFooter())}
    >
      {content}
    </Modal>
  );
};

export default DraggableModal;
DraggableModal.defaultProps = {
  onOk: null,
  okText: '',
  cancelText: '',
  className: '',
  footer: null,
  style: {},
  centered: true,
};
DraggableModal.propTypes = {
  content: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  className: PropTypes.string,
  footer: PropTypes.arrayOf(PropTypes.any),
  style: PropTypes.objectOf(PropTypes.string),
  centered: PropTypes.bool,
};
