/* eslint-disable camelcase */

// export const  Base_URL=process.env.API_BASE_URL;
export const Base_URL = process.env.REACT_APP_API_BASE_URL;
export const SAP_URL = process.env.REACT_APP_SAP_URL;
export const Get_MaterialList_URL = 'material-list';
export const Get_MaterialList_Aggr_URL = 'material-list-aggregates';
export const Get_MaterialColumns_URL = 'material-columns';
export const Get_MaterialList_SearchCount_URL = 'material-list-search-count';
export const ShareView = 'share-view';
export const Get_Plants_URL = 'plants';
export const Get_Company_Codes_URL = 'company-codes';
export const Get_Functional_Locations_List_URL = 'get-functional-locations';
export const Get_Optimal_Inventory_By_Strategy_URL = 'optimal-inventory-status';
export const Get_Optimal_Inventory_By_Group_URL = 'optimal-inventory-group';
export const Get_Inventory_Value_By_Time_URL = 'inventory-value-time';
export const Get_Optimal_Inventory_By_Time_URL = 'optimal-inventory-time';
export const Get_Optimal_Inventory_By_SP_URL = 'optimal-inventory-sp';
export const Get_Optimal_Inventory_By_Crit_URL = 'optimal-inventory-criticality';
export const Get_Planned_Orders_URL = 'planned-orders';
export const Get_Optimal_Inventory_By_ROP_URL = 'optimal-inventory-rop';
export const Get_Excess_Stock_By_Status = 'excess-stock-by-status';
export const Get_Inventory_Value_URL = 'inventory-value';
export const Get_Total_Excess_Stock_Value = 'total-excess-stock';
export const MassUpdateMrpChangelog = 'mass-update-mrp-changelog';
export const MassUpdateMrpChangelogRevert = 'mass-update-mrp-changelog-revert';
export const Get_Clean_Up_Widget_URL = 'clean-up-widget';
export const Get_Monetary_Impact = 'monetary-impact';
export const Get_Material_Ids = 'get-material-ids';
export const Create_Worklist_URL = 'create-worklist';
export const Update_Worklist_URL = 'update-worklist';
export const Get_Worklists_URL = 'get-worklists';
export const Get_WorklistDetails_URL = 'worklist-details';
export const Delete_Worklist_URL = 'delete-worklist';
export const Get_All_WorklistsCount_URL = 'all-worklists-count';
export const GetConsumptionHistoryURL = 'consumption-history';
export const GetBOMExplosion = 'bill-of-materials';
export const GetReservations = 'reservations';
export const GetOpenDocs = 'open-docs';
export const ChangeLogs = 'changelogs';
export const GetMaterialLeadTime = 'material-leadtime';
export const GetXPlant = 'xplant';
export const GetReturnDocument = 'return-document';
export const GetMaterialChangeLogs = 'material-changelogs';
export const GetMaterialMovement = 'material-movement';
export const GetFunctionalLocation = 'functional-location';
export const GetPlantRelationships = 'relationships';
export const GetLongText = 'long-text';
export const GetClassification = 'classification';
export const GetWarehouse = 'warehouse';
export const GetWarehouseByPlant = 'warehouse-by-plant';
export const FixedBin = 'fixed-bin';
export const PlannedOrder = 'planned-orders';
export const GetPurchasing = 'purchasing';
export const SetUserSetting = 'set-user-setting';
export const GetAllUserSettings = 'all-user-settings';
export const GetCurrencyList = 'get-currency-list';
export const Get_Dropdown_Values = 'dropdown-values';
export const Simulate_URL = 'simulate';
export const Sap = 'sap/';
export const SapMRP = 'mrp';
export const SapMRPs = 'mrps';
export const SapMaterialMemo = 'material-memo';
export const SapMaterialMemos = 'material-memos';
export const MassUpdateChangelog = 'mass-update-changelog';
export const MassUpdateLastChecked = 'last-checked-date-mass-update';
export const SapPlannedOrderText = 'planned-order-writeback';
export const SapPlndOrdMassUpdate = 'planned-order-mass-update';
export const PlannedOrderChangelog = 'memo-changelog';
export const GetColumnMapper = 'column-mapper';
export const IotaMassUpdateComments = 'iota-memo-mass-update';
export const WarehouseMassUpdate = 'warehouse-memo-mass-update';
export const User_Views = 'user-views';
export const Public_User_Views = 'public-user-views';
export const Create_User_View = 'create-user-view';
export const Create_Public_User_View = 'create-public-user-view';
export const StaticWorklist = 'static-worklist';
export const SaveSharedSWL = 'save-shared-static-wl';
export const SharedStaticWorklist = 'shared-static-worklist';
export const StaticWorklists = 'static-worklists';
export const RelevantStaticWorklists = 'relevant-static-worklists';
export const SWLAddItems = 'add-items';
export const MMSWLUpdateItems = 'update-mm-items';
export const GenerateCSV = 'generate-CSV';
export const Postpone = 'postpone';
export const Inspect = 'inspect';
export const Review = 'review';
export const Delegate = 'delegate';
export const GetOptInvTot = 'optimal-inventory-total';
export const Notifications = 'notifications';
export const Comments = 'comments';
export const WarehouseComments = 'warehouse-comments';
export const Config = 'config';
export const GetPlantGroups = 'plant-groups';
export const DeletePlantGroup = 'delete-plant-group';
export const CreatePlantGroup = 'create-plant-group';
export const UpdatePlantGroupNameURL = 'update-plant-group-name';
export const Outliers = 'outliers';
export const DeleteMultiplePlantGroups = 'delete-multiple-plant-groups';
export const newStockSimulation = 'new-stock';
export const storeNewStockSessionFormURL = 'store-current-session-new-stock-form';
export const saveNewStockFormURL = 'save-new-stock-form';
export const getNewStockFormsURL = 'get-all-new-stock-forms';
export const deleteNewStockFormsURL = 'delete-new-stock-form';
export const Get_MaterialMaster_URL = 'material-master';
export const Get_MaterialMaster_Columns_URL = 'material-master-columns';
export const Get_MaterialMaster_SearchCount_URL = 'material-master-search-count';
export const MMStaticWorklists = 'material-master-static-wls';
export const GetWarnings = 'warnings';
export const GetOutdated = 'outdated';
export const Track = 'track';
export const GetMonetaryImpactSummary = 'monetary-impact-summary';
export const UpdateMonetaryImpactSummary = 'update-monetary-impact-summary';
export const ESStatus = 'get-status';
export const GetWidgetViews = 'get-widget-views';
export const BindViewToWidget = 'save-widget-view';
export const UnBindViewToWidget = 'delete-widget-view';
