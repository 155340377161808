import React, { useEffect, useState } from 'react';
import {
  Tooltip, Checkbox, Button, Table,
  Input,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  CloseOutlined, InfoCircleOutlined,
} from '@ant-design/icons';
import styles from './styles.module.scss';
import './modal.css';
import allActions from '../../../actions';
import { getCorrectTokenFromUser } from '../../../utils/token';
import { localDateFormat, formatMaterialNumber } from '../DateAndNumberFunctions';
import DraggableModal from '../DraggableModal';
import { showNotification } from '../../../actions/utilities/Notifications/NotificationActions';

import { SAP_URL } from '../../../actions/APIUrls';

const reGoodDate = /^(?:(0[1-9]|[12][0-9]|3[01])[/.](0[1-9]|1[012])[/.](19|20)[0-9]{2})$/;

const defaultObject = {};

const parseToCorrectDate = (value) => {
  if (value) {
    const dateParts = value.replaceAll('.', '/').split('/');
    const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    return dateObject;
  }
  return new Date();
};

const calculatereqPostDate = (date, grDays) => {
  const newDate = new Date(date);
  if (newDate) {
    const correctGrDaysValue = (grDays === 0 || grDays > 0) ? grDays : 10;
    newDate.setDate(newDate.getDate() - correctGrDaysValue);
  }
  return localDateFormat(newDate);
};

const calculateDeliveryDate = (date, leadTime) => {
  const now = new Date();
  let newDate = new Date(date);
  if (newDate < now) {
    newDate = now;
  }
  const correctLeadTimeValue = (leadTime === 0 || leadTime > 0) ? leadTime : 10;
  newDate.setDate(newDate.getDate() + correctLeadTimeValue);
  return localDateFormat(newDate);
};

const createDateFormat = (date) => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + 2);
  const iso = newDate.toISOString();
  const splitOnTimeZone = iso.split('.');
  return splitOnTimeZone[0];
};

const PlannedOrderModal = ({
  isVisible, selectedMaterials, close, materialViewState,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const user = useSelector((state) => state.authState.user);
  const [dataTransferObject, setDataTransferObject] = useState(defaultObject);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [populateAll, setPopulateAll] = useState({
    qty: '', deliveryDate: '', reqPostDate: '', grDays: '',
  });
  const [excludeList, setExcludeList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [fetchedMrpData, setFetchedMrpData] = useState([]);
  const [fetchedMatData, setFetchedMatData] = useState([]);
  const [fetchedPLData, setFetchedPLData] = useState([]);
  const [showRequisitions, setShowRequisitions] = useState(false);

  const submitPlannedOrders = async () => {
    const dataToSend = [];
    setLoading(true);
    Object.entries(dataTransferObject).forEach((v) => {
      if (!excludeList.includes(v[0])) {
        if (v[1]?.qty) {
          const correctDate = createDateFormat(parseToCorrectDate(v[1]?.deliveryDate));
          const obj = {
            PlannedOrderNumber: v[0],
            Quantity: v[1].qty,
            DeliveryDate: correctDate,
          };
          dataToSend.push(obj);
        }
      }
    });

    if (dataToSend.length === 0) {
      dispatch(showNotification({
        Type: 'error',
        Title: 'No data to send',
        Message: 'Please select at least one material',
      }));
      setLoading(false);
      return { ...dataTransferObject };
    }

    const toPlannedOrder = {
      PlannedOrderBatch: '123',
      to_PlannedOrder: dataToSend,
    };
    const returnedData = await allActions.SapAPIActions.setPlannedOrders(toPlannedOrder, getCorrectTokenFromUser(user), dispatch);
    if (!returnedData?.error) {
      const onlyValidMaterials = dataSource.filter((v) => !excludeList.includes(v.PLANNED_ORDER_NUMBER) && v.isValid);
      setDataSource([...onlyValidMaterials.map((values) => {
        const result = returnedData?.data?.find((val) => val.PlannedOrderNumber.toString() === values.PLANNED_ORDER_NUMBER.toString() && values.PLANNED_ORDER_NUMBER.toString() !== 'Multiple');
        if (result) {
          return {
            ...values,
            requisitionNumber: result.RequisitionNumber,

          };
        }
        return { ...values };
      })]);
      setShowRequisitions(true);
    }
    setLoading(false);
  };

  const createChunks = (list) => {
    const chunkSize = 150;
    const arrayOfArrays = [];

    const arrayToUse = list;

    for (let i = 0; i < arrayToUse.length; i += chunkSize) {
      const chunk = arrayToUse.slice(i, i + chunkSize);
      arrayOfArrays.push(chunk);
    }
    return arrayOfArrays;
  };

  const getMrpData = async (list, plant) => {
    const mrpData = [];
    if (selectedMaterials.length > 150) {
      const arrayOfArrays = createChunks(list);

      await Promise.all(arrayOfArrays.map(async (arr) => {
        const data = await allActions.SapAPIActions.getMRPsWithFetch([plant], arr, getCorrectTokenFromUser(user), dispatch);
        mrpData.push(...data);
      }));
    } else {
      const data = await allActions.SapAPIActions.getMRPsWithFetch([plant], list, getCorrectTokenFromUser(user), dispatch);
      mrpData.push(...data);
    }

    return mrpData;
  };

  const initialData = (matList, mrpList, PLList) => {
    matList.forEach((value) => {
      const pld = PLList.find((pld) => formatMaterialNumber(pld.MaterialNumber) === value.MATERIAL_TYPE_SAP_ID);
      const mrp = mrpList.find((mrp) => formatMaterialNumber(mrp.MaterialNumber) === value.MATERIAL_TYPE_SAP_ID.toString());
      value.isValid = true;
      value.hasMultiple = false;
      if (value.PLANNED_ORDER_NUMBER === 'Multiple') {
        value.hasMultiple = true;
        setExcludeList((prev) => [...prev, value.PLANNED_ORDER_NUMBER]);
      } else if (!pld) {
        value.isValid = false;
        setExcludeList((prev) => [...prev, value.PLANNED_ORDER_NUMBER]);
      } else {
        value.plannedOrderData = pld;
        let reqPostDateAsText = '';
        if (value.EARL_REQ_DATE) {
          const earlReqDate = new Date(value.EARL_REQ_DATE);

          const reqPostDate = earlReqDate.setDate(earlReqDate.getDate() - (value?.GR_PROCESS_TIME || 0));

          reqPostDateAsText = localDateFormat(reqPostDate);
          value.reqPostDate = reqPostDateAsText;
        } else {
          value.reqPostDate = '';
        }
        value.deliveryDate = calculateDeliveryDate(new Date(), value.CC_LEAD_TIME);
        const showReqPostDateAndGrDays = value.EARL_REQ_DATE && value.PLANNED_ORDER_STATUS === 'Open reservations with insufficient coverage';
        setDataTransferObject((prev) => ({
          ...prev,
          [value.PLANNED_ORDER_NUMBER]: {
            ...prev[value.PLANNED_ORDER_NUMBER], earlReqDate: value.EARL_REQ_DATE ? localDateFormat(value.EARL_REQ_DATE) : '', qty: value.plannedOrderData.TotalPlannedQuantity, deliveryDate: value?.deliveryDate, reqPostDate: showReqPostDateAndGrDays ? reqPostDateAsText : '', grDays: showReqPostDateAndGrDays ? value.GR_PROCESS_TIME || 0 : '', leadTime: value.CC_LEAD_TIME || 10,
          },
        }));
      }
      if (mrp) {
        value.mrpData = mrp;
      }
    });
    setDataSource(matList);
    setLoading(false);
  };

  const createDataStructure = async (list, plant) => {
    const plantMaterials = list.map((materialID) => ({
      plantID: plant,
      materialID,
    }));
    const matData = await allActions.MaterialDetailsActions.fetchPlannedOrderData(plantMaterials, getCorrectTokenFromUser(user), dispatch);
    setFetchedMatData(matData);
    const mrpData = await getMrpData(list, plant);
    setFetchedMrpData(mrpData);
    const plannedOrderNumbers = matData.map((mat) => mat.PLANNED_ORDER_NUMBER);
    const plannedOrderdata = await allActions.SapAPIActions.getPlannedOrders(plannedOrderNumbers, getCorrectTokenFromUser(user), dispatch);
    setFetchedPLData(plannedOrderdata);
    initialData(matData, mrpData, plannedOrderdata);
  };

  const clear = () => {
    setDataTransferObject(defaultObject);
    setDataSource([]);
    setExcludeList([]);
    setPopulateAll({
      qty: '', deliveryDate: '', reqPostDate: '', grDays: '',
    });
    setSelectAll(false);
    setFetchedMatData([]);
    setFetchedMrpData([]);
    setFetchedPLData([]);
    setShowRequisitions(false);
  };

  const reset = () => {
    initialData(fetchedMatData, fetchedMrpData, fetchedPLData);
    setPopulateAll({
      qty: '', deliveryDate: '', reqPostDate: '', grDays: '',
    });
    setShowRequisitions(false);
  };

  useEffect(() => {
    if (selectedMaterials?.length > 0 && isVisible) {
      clear();
      setExcludeList([]);
      setLoading(true);
      const plants = [];
      const materials = [];
      selectedMaterials.forEach((mats) => {
        materials.push(mats.materialID);
        if (!plants.includes(mats.plantID) && mats.plantID && mats.materialID) {
          plants.push(mats.plantID);
        }
      });
      if (plants.length === 1) {
        createDataStructure(materials, plants[0]);
      } else {
        dispatch(showNotification({
          Type: 'error',
          Title: 'Multiple plants',
          Message: 'Please only use materials related to the same plant',
        }));
        setLoading(false);
      }
    } if (!isVisible) {
      clear();
    }
  }, [selectedMaterials, isVisible]);

  const getRowClassName = (record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark');

  const calculateCorrectValues = (PLNumber, type, value) => {
    const prev = dataTransferObject[PLNumber];
    const object = { ...prev };
    if (type === 'reqPostDate') {
      object.reqPostDate = value;
      object.deliveryDate = calculateDeliveryDate(parseToCorrectDate(value), prev?.leadTime);
    } else if (type === 'grDays') {
      const newGrDays = value !== '' ? Number(value) : 0;
      object.grDays = newGrDays;
      object.reqPostDate = calculatereqPostDate(parseToCorrectDate(prev?.earlReqDate), newGrDays);
    } else if (type === 'deliveryDate') {
      object.deliveryDate = value;
    }
    setDataTransferObject((v) => ({ ...v, [PLNumber]: object }));
  };

  const updateChanges = (value, type, plannedOrderNumber) => {
    setDataTransferObject((prev) => ({ ...prev, [plannedOrderNumber]: { ...prev[plannedOrderNumber], [type]: value } }));
    if (reGoodDate.test(value) && value.length === 10) {
      calculateCorrectValues(plannedOrderNumber, type, value);
    } else if (type === 'grDays') {
      calculateCorrectValues(plannedOrderNumber, type, value);
    }
  };

  const populateAllMaterials = () => {
    dataSource.forEach((v) => {
      if (v.isValid && !excludeList.includes(v.PLANNED_ORDER_NUMBER)) {
        const prev = dataTransferObject[v.PLANNED_ORDER_NUMBER];
        const newObject = { ...prev };
        if (populateAll?.deliveryDate && reGoodDate.test(populateAll?.deliveryDate)) {
          newObject.deliveryDate = populateAll?.deliveryDate;
        }
        if (populateAll.reqPostDate && reGoodDate.test(populateAll.reqPostDate)) {
          newObject.reqPostDate = populateAll.reqPostDate;
          if (!populateAll?.deliveryDate || !reGoodDate.test(populateAll?.deliveryDate)) {
            newObject.deliveryDate = calculateDeliveryDate(parseToCorrectDate(populateAll.reqPostDate), prev.leadTime);
          }
        }
        if (populateAll.grDays) {
          const newGrDays = populateAll.grDays !== '' ? Number(populateAll.grDays) : 0;
          newObject.grDays = newGrDays;
          if (!populateAll.reqPostDate || !reGoodDate.test(populateAll.reqPostDate)) {
            newObject.reqPostDate = calculatereqPostDate(parseToCorrectDate((populateAll?.earlReqDate && reGoodDate.test(populateAll?.earlReqDate)) ? populateAll?.earlReqDate : prev?.earlReqDate), newGrDays);
          }
        }
        if (populateAll.qty) newObject.qty = populateAll.qty;
        setDataTransferObject((prev) => ({ ...prev, [v.PLANNED_ORDER_NUMBER]: { ...prev[v.PLANNED_ORDER_NUMBER], ...newObject } }));
      }
    });
  };

  const setDeliveryDateToReqPostDate = () => {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    dataSource.forEach((v) => {
      if (v.isValid && !excludeList.includes(v.PLANNED_ORDER_NUMBER)) {
        const prev = dataTransferObject[v.PLANNED_ORDER_NUMBER];
        const object = { ...prev };
        if (object.reqPostDate) {
          const reqDate = parseToCorrectDate(object.reqPostDate);
          if (reqDate < now) {
            object.deliveryDate = localDateFormat(now);
          } else {
            object.deliveryDate = localDateFormat(reqDate);
          }
        }
        setDataTransferObject((a) => ({ ...a, [v.PLANNED_ORDER_NUMBER]: object }));
      }
    });
  };

  const hasValidData = () => {
    let isValid = true;
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    Object.entries(dataTransferObject).forEach((v) => {
      const deliveryDate = new Date(parseToCorrectDate(v[1]?.deliveryDate));
      deliveryDate.setHours(0, 0, 0, 0);
      if (!reGoodDate.test(v[1]?.deliveryDate) || deliveryDate < now) {
        isValid = false;
      }
      if (v[1]?.qty === '') {
        isValid = false;
      }
    });
    return isValid;
  };

  const validDate = (value) => {
    const error = '1px solid red';
    if (value === '') return {};
    if (reGoodDate.test(value)) {
      const checkedDate = new Date(parseToCorrectDate(value));
      const now = new Date();
      checkedDate.setHours(0, 0, 0, 0);
      now.setHours(0, 0, 0, 0);
      if (checkedDate >= now) return {};
    }

    return { border: error };
  };

  const handleSelectAll = (e) => {
    const { checked } = e?.target;
    setSelectAll(!selectAll);
    dataSource.forEach((src) => {
      if (checked) {
        if (excludeList.includes(src.PLANNED_ORDER_NUMBER) && !src.hasMultiple && src.isValid) {
          setExcludeList((prev) => [...prev.filter((item) => item !== src.PLANNED_ORDER_NUMBER)]);
        }
      } else if (!excludeList.includes(src.PLANNED_ORDER_NUMBER)) {
        setExcludeList((prev) => [...prev, src.PLANNED_ORDER_NUMBER]);
      }
    });
  };

  const validMaterial = (mat) => {
    if (mat.PLANNED_ORDER_NUMBER === 'Multiple') return false;
    return true;
  };

  const getSortDirection = () => {
    if (materialViewState?.sortDirection === 'ASC') return 'ascend';
    return 'descend';
  };

  const columns = [
    {
      children: [{
        title: <Checkbox
          defaultChecked={false}
          checked={selectAll}
          onChange={handleSelectAll}
          indeterminate={!selectAll && excludeList.length !== dataSource.length}
          disabled={showRequisitions}
        />,
        render: (v) => {
          const handleChange = (val) => {
            if (val.target?.checked) {
              setExcludeList(excludeList.filter((item) => item !== v.PLANNED_ORDER_NUMBER));
            } else {
              setSelectAll(false);
              setExcludeList([...excludeList, v.PLANNED_ORDER_NUMBER]);
            }
          };
          return (
            <Tooltip title="">
              <Checkbox
                defaultChecked={v.isValid}
                checked={!excludeList?.includes(v.PLANNED_ORDER_NUMBER)}
                disabled={!v.isValid || !validMaterial(v) || showRequisitions}
                onChange={handleChange}
              />
            </Tooltip>
          );
        },
        width: '30px',
      }],
    },
    /* {
      children: [{
        title: 'Pl Ord No',
        render: (v) => <div style={{ width: '100%', textAlign: 'right' }}>{v.PLANNED_ORDER_NUMBER}</div>,
        width: '90px',
        sorter: (a, b) => {
          if (a.PLANNED_ORDER_NUMBER > b.PLANNED_ORDER_NUMBER) return -1;
          if (a.PLANNED_ORDER_NUMBER < b.PLANNED_ORDER_NUMBER) return 1;
          return 0;
        },
      }],
    },
    */
    {
      children: [{
        title: 'Plant',
        render: (v) => <div style={{ width: '100%', textAlign: 'right' }}>{v.PLANT_FACILITY_SAP_ID}</div>,
        width: '60px',
      }],
    },
    {
      children: [{
        title: 'Material',
        ...((materialViewState?.sortColumn === 'MATERIAL_TYPE_SAP_ID' || typeof materialViewState?.sortColumn === 'undefined') && { defaultSortOrder: getSortDirection() }),
        render: (v) => {
          const isMatDetails = window.location.pathname.includes('material-details');
          if (isMatDetails) {
            return <div style={{ width: '100%', textAlign: 'right' }}>{v.MATERIAL_TYPE_SAP_ID}</div>;
          }
          return (
            <a href={`material-details/${v.PLANT_FACILITY_SAP_ID}/${v.MATERIAL_TYPE_SAP_ID}`} style={{ width: '100%', textAlign: 'right' }} target="_blank" rel="noreferrer">{v.MATERIAL_TYPE_SAP_ID}</a>
          );
        },
        sorter: (a, b) => {
          if (Number(a.MATERIAL_TYPE_SAP_ID) > Number(b.MATERIAL_TYPE_SAP_ID)) return -1;
          if (Number(a.MATERIAL_TYPE_SAP_ID) < Number(b.MATERIAL_TYPE_SAP_ID)) return 1;
          return 0;
        },
        width: '90px',
      }],
    },
    {
      children: [{
        title: 'Material Description',
        render: (v) => (
          <Tooltip title={v.EDAM_MATERIAL_TYPE_NM}>
            <div style={{
              width: '100%', textAlign: 'right', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
            }}
            >
              {v.EDAM_MATERIAL_TYPE_NM}
            </div>
          </Tooltip>
        ),
        width: '160px',
      }],
    },
    (!showRequisitions
      ? {
        children: [{
          title: 'LT Calc',
          ...(materialViewState?.sortColumn === 'CC_LEAD_TIME' && { defaultSortOrder: getSortDirection() }),
          render: (v) => <div style={{ width: '100%', textAlign: 'right' }}>{v?.CC_LEAD_TIME}</div>,
          width: '80px',
          sorter: (a, b) => {
            if (a.CC_LEAD_TIME > b.CC_LEAD_TIME) return -1;
            if (a.CC_LEAD_TIME < b.CC_LEAD_TIME) return 1;
            return 0;
          },
        }],
      } : { width: '0px' }),
    (!showRequisitions
      ? {
        children: [{
          title: 'LT Source',
          ...(materialViewState?.sortColumn === 'CC_LT_SOURCE' && { defaultSortOrder: getSortDirection() }),
          render: (v) => (
            <Tooltip title={v.CC_LT_SOURCE}>
              <div style={{
                width: '100%', textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',
              }}
              >
                {v.CC_LT_SOURCE}
              </div>
            </Tooltip>
          ),
          width: '120px',
          sorter: (a, b) => {
            if (a.CC_LT_SOURCE > b.CC_LT_SOURCE) return -1;
            if (a.CC_LT_SOURCE < b.CC_LT_SOURCE) return 1;
            return 0;
          },
        }],
      } : { width: '0px' }),
    {
      children: [{
        title: 'Market Price',
        ...(materialViewState?.sortColumn === 'MARKET_PRICE' && { defaultSortOrder: getSortDirection() }),
        render: (v) => (
          <Tooltip
            title={(
              <div className={styles.openDocsTooltip}>
                <span>
                  Fair price:
                  {' '}
                  {v?.FAIR_PRICE}
                  {' '}
                  {v?.COMPANY_CURRENCY_SAP_CD}
                </span>
                <span>
                  Market Price:
                  {' '}
                  {v.MARKET_PRICE ? `${v?.MARKET_PRICE} ${v?.COMPANY_CURRENCY_SAP_CD}` : '-'}
                </span>
                <span>
                  Moving average price:
                  {' '}
                  {v?.CC_UNIT_COST_AMT}
                  {' '}
                  {v?.COMPANY_CURRENCY_SAP_CD}
                </span>
              </div>
            )}
          >
            <div style={{ width: '100%', textAlign: 'right', height: '100%' }}>
              {v.MARKET_PRICE ? v?.MARKET_PRICE?.toFixed(0) : <InfoCircleOutlined />}
            </div>
          </Tooltip>
        ),
        width: '70px',
        sorter: (a, b) => {
          if (a.FAIR_PRICE > b.FAIR_PRICE) return -1;
          if (a.FAIR_PRICE < b.FAIR_PRICE) return 1;
          return 0;
        },
      }],
    },
    {
      children: [{
        title: 'Earl Req Date',
        ...(materialViewState?.sortColumn === 'EARL_REQ_DATE' && { defaultSortOrder: getSortDirection() }),
        render: (v) => {
          if (v.PLANNED_ORDER_STATUS !== 'Open reservations with insufficient coverage') return '';
          if (v.EARL_REQ_DATE) {
            const now = new Date();
            now.setHours(0, 0, 0, 0);
            const reqDate = new Date(v.EARL_REQ_DATE);
            reqDate.setHours(0, 0, 0, 0);
            return <div style={{ color: reqDate < now ? '#ff0000' : 'inherit' }}>{localDateFormat(v.EARL_REQ_DATE)}</div>;
          }
          return '';
        },
        width: '100px',
        sorter: (a, b) => {
          if (a.EARL_REQ_DATE > b.EARL_REQ_DATE) return -1;
          if (a.EARL_REQ_DATE < b.EARL_REQ_DATE) return 1;
          return 0;
        },
      }],
    },
    (!showRequisitions
      ? {
        title: () => (
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Input
              Number
              value={populateAll.grDays}
              onChange={(e) => {
                const { value } = e?.target;
                setPopulateAll((prev) => ({ ...prev, grDays: value }));
              }}
            />
          </div>
        ),
        children: [{
          title: 'GR Days',
          ...(materialViewState?.sortColumn === 'CONTRACT_VENDOR_LT' && { defaultSortOrder: getSortDirection() }),
          render: (v) => {
            if (v.PLANNED_ORDER_STATUS !== 'Open reservations with insufficient coverage') return '';
            const hasChanges = dataTransferObject[v.PLANNED_ORDER_NUMBER];

            const valueToUse = () => {
              if (!v.isValid || !validMaterial(v)) return '';
              if (hasChanges) return hasChanges.grDays;
              if (v.GR_PROCESS_TIME) return v.GR_PROCESS_TIME;
              return v.isValid ? '10' : '';
            };
            return <div style={{ width: '100%', textAlign: 'right' }}><Input Number disabled={!v.isValid || !validMaterial(v)} value={valueToUse()} onChange={(e) => updateChanges(e.target.value, 'grDays', v.PLANNED_ORDER_NUMBER)} /></div>;
          },
          width: '80px',
        }],
      } : { width: '0px' }),
    (!showRequisitions
      ? {
        title: () => (
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Input
              Number
              value={populateAll.reqPostDate}
              style={validDate(populateAll.reqPostDate)}
              onChange={(e) => {
                const { value } = e?.target;
                setPopulateAll((prev) => ({ ...prev, reqPostDate: value }));
              }}
            />
          </div>
        ),
        children: [{
          title: 'Req. Post. Date',
          render: (v) => {
            if (v.PLANNED_ORDER_STATUS !== 'Open reservations with insufficient coverage') return '';
            if (validMaterial(v)) {
              const value = dataTransferObject[v.PLANNED_ORDER_NUMBER]?.reqPostDate || '';

              return (
                <div style={{ width: '100%', textAlign: 'right' }}>
                  <Input Number disabled={!v.isValid} value={value} onChange={(e) => updateChanges(e.target.value, 'reqPostDate', v.PLANNED_ORDER_NUMBER)} style={validDate(value)} />
                </div>
              );
            }
            return (
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Input Number disabled />
              </div>
            );
          },
          width: '100px',
        }],
      } : { width: '0px' }),
    {
      title: () => {
        if (showRequisitions) return '';
        return (
          <div style={{ width: '100%', textAlign: 'right' }}>
            <Input
              Number
              value={populateAll?.deliveryDate}
              style={validDate(populateAll?.deliveryDate)}
              onChange={(e) => {
                const { value } = e?.target;
                setPopulateAll((prev) => ({ ...prev, deliveryDate: value }));
              }}
            />
          </div>
        );
      },
      children: [{
        title: 'Del. Date Pur. Req',
        render: (v) => {
          if (!v.isValid || !validMaterial(v)) {
            return (
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Input Number disabled />
              </div>
            );
          }
          const value = dataTransferObject[v.PLANNED_ORDER_NUMBER]?.deliveryDate;
          if (showRequisitions) return <div style={{ width: '100%', textAlign: 'right' }}>{value}</div>;

          return (
            <div style={{ width: '100%', textAlign: 'right' }}>
              <Input Number disabled={!v.isValid} value={value} onChange={(e) => updateChanges(e.target.value, 'deliveryDate', v.PLANNED_ORDER_NUMBER)} style={validDate(value)} />
            </div>
          );
        },
        width: '120px',
      }],
    },
    (showRequisitions
      ? {
        title: '',
        children: [{
          title: 'Urgent',
          render: (v) => {
            const dataTransfer = dataTransferObject[v.PLANNED_ORDER_NUMBER];
            const submittedDeliveryDate = new Date(parseToCorrectDate(dataTransfer.deliveryDate));
            const nowPlusLeadTime = new Date();
            submittedDeliveryDate.setHours(0, 0, 0, 0);
            nowPlusLeadTime.setHours(0, 0, 0, 0);
            nowPlusLeadTime.setDate(nowPlusLeadTime.getDate() + v.CC_LEAD_TIME);
            if (submittedDeliveryDate < nowPlusLeadTime) return <CloseOutlined style={{ color: '#ff0000' }} />;
            return '';
          },
          width: '60px',
        }],
      } : { width: '0px' }),
    (!showRequisitions
      ? {
        title: <Input
          value={populateAll.qty}
          onChange={(e) => {
            const { target } = e;
            setPopulateAll((prev) => ({ ...prev, qty: target?.value }));
          }}
        />,
        children: [{
          title: 'Qty',
          render: (v) => {
            const valueToUse = () => {
              if (!v.isValid || !validMaterial(v)) return '';
              if (dataTransferObject[v.PLANNED_ORDER_NUMBER]?.qty === '' || dataTransferObject[v.PLANNED_ORDER_NUMBER]?.qty) return dataTransferObject[v.PLANNED_ORDER_NUMBER].qty;
              if (v.CC_PLANNED_ORDER_QTY) return v.CC_PLANNED_ORDER_QTY;
              return '';
            };

            return <div style={{ width: '100%', textAlign: 'right' }}><Input disabled={!v.isValid || !validMaterial(v)} value={valueToUse()} onChange={(e) => updateChanges(e.target.value, 'qty', v.PLANNED_ORDER_NUMBER)} /></div>;
          },
          width: '60px',
        }],
      } : { width: '0px' }),
    (!showRequisitions
      ? {
        children: [{
          title: 'Multiple Pl.Ord',
          render: (v) => (v.hasMultiple ? <Tooltip title="Multiple planned orders. Please handle this in SAP."><CloseOutlined style={{ color: '#ff0000' }} /></Tooltip> : ''),
          width: '80px',
          sorter: (a, b) => {
            if (a.hasMultiple > b.hasMultiple) return -1;
            if (a.hasMultiple < b.hasMultiple) return 1;
            return 0;
          },
        }],
      } : { width: '0px' }),
    (!showRequisitions
      ? {
        children: [{
          title: 'Red status',
          render: (v) => {
            if (v.STATUS_LIGHT?.toLowerCase() === 'red') return <CloseOutlined style={{ color: '#ff0000' }} />;
            return '';
          },
          width: '70px',
          sorter: (a, b) => {
            if (a.STATUS_LIGHT > b.STATUS_LIGHT) return -1;
            if (a.STATUS_LIGHT < b.STATUS_LIGHT) return 1;
            return 0;
          },
        }],
      } : { width: '0px' }),
    (!showRequisitions
      ? {
        children: [{
          title: 'Earl Req not met',
          render: (v) => {
            if (v.deliveryDate && v.EARL_REQ_DATE) {
              const deliveryDate = new Date(parseToCorrectDate(v.deliveryDate));
              const reqDate = new Date(v.EARL_REQ_DATE);
              if (deliveryDate >= reqDate && v.PLANNED_ORDER_STATUS === 'Open reservations with insufficient coverage') return <Tooltip title={`Earliest required date: ${localDateFormat(v.EARL_REQ_DATE)}`}><CloseOutlined style={{ color: '#ff0000' }} /></Tooltip>;
            }
            return '';
          },
          sorter: (a, b) => {
            const checkA = new Date(parseToCorrectDate(a.deliveryDate)) > new Date(a.EARL_REQ_DATE);
            const checkB = new Date(parseToCorrectDate(b.deliveryDate)) > new Date(b.EARL_REQ_DATE);
            if (checkA > checkB) return -1;
            if (checkA < checkB) return 1;
            return 0;
          },
          width: '90px',

        }],
      } : { width: '0px' }),
    (!showRequisitions
      ? {
        children: [{
          title: 'Open PR exists',
          render: (v) => {
            if (v.OPEN_PR_QTY > 0) return <Tooltip title={`Open purchase requisition qty: ${v.OPEN_PR_QTY}`}><CloseOutlined style={{ color: '#ff0000' }} /></Tooltip>;
            return '';
          },
          width: '70px',
          sorter: (a, b) => {
            const checkA = a.OPEN_PR_QTY > 0;
            const checkB = b.OPEN_PR_QTY > 0;
            if (checkA > checkB) return -1;
            if (checkA < checkB) return 1;
            return 0;
          },
        }],
      } : { width: '0px' }),
    (!showRequisitions
      ? {
        children: [{
          title: 'Mulitple Res',
          render: (v) => {
            if (v.RESERVATIONS) {
              const allReservations = v.RESERVATIONS.split(';');
              if (allReservations.length === 1) return '';

              const sorted = allReservations
                .filter((res) => res) // Filter out empty strings
                .sort((a, b) => {
                  const dateA = new Date(a.split('|')[0] || 0);
                  const dateB = new Date(b.split('|')[0] || 0);
                  return dateA - dateB;
                });

              return (
                <Tooltip title={(
                  <div className={styles.reservationTooltip}>
                    {
                      sorted.map((reservation) => {
                        const reservationData = reservation.split('|');
                        return (
                          <div className={styles.reservationWorkOrder}>
                            <span style={{ fontWeight: 'bold' }}>Mat Req Dt:</span>
                            {reservationData[0] ? localDateFormat(reservationData[0]) : '-'}
                            {' '}
                            <span style={{ fontWeight: 'bold' }}>Qty:</span>
                            {reservationData[1] ? Number(reservationData[1]).toFixed(0) : '-'}
                            {' '}
                            <span style={{ fontWeight: 'bold' }}>Work Order:</span>
                            {reservationData[2] ? reservationData[2] : '-'}
                          </div>
                        );
                      })
                    }
                  </div>
)}
                >
                  <CloseOutlined style={{ color: '#ff0000' }} />
                </Tooltip>
              );
            }
            return '';
          },
          width: '70px',
          sorter: (a, b) => {
            const checkA = a.OPEN_PR_QTY > 0;
            const checkB = b.OPEN_PR_QTY > 0;
            if (checkA > checkB) return -1;
            if (checkA < checkB) return 1;
            return 0;
          },
        }],
      } : { width: '0px' }),
    (!showRequisitions
      ? {
        children: [{
          title: 'ROP deviation',
          render: (v) => {
            if (v.mrpData) {
              if (v.CC_ROP === null || v.CC_ROP === 'undefined') return '';
              const sapRop = Number(v.mrpData.ReorderPoint);
              if (sapRop !== v.CC_ROP) {
                return (
                  <Tooltip title={(
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                      <div>{`Recommended: ${v.CC_ROP}`}</div>
                      <div>{`Current: ${sapRop}`}</div>
                    </div>
)}
                  >
                    <CloseOutlined style={{ color: '#ff0000' }} />
                  </Tooltip>
                );
              }
            }
            return v.ROP_DEVIATION?.toString() !== '0' ? <Tooltip title="Using IOTA ROP deviation status. This status may be outdated. Verify correct ROP in SAP."><CloseOutlined style={{ color: '#ff0000' }} /></Tooltip> : '';
          },
          width: '80px',
          sorter: (a, b) => {
            const checkA = a.mrpData && a.CC_ROP ? Number(a.mrpData.ReorderPoint) !== a.CC_ROP : a.ROP_DEVIATION;
            const checkB = b.mrpData && b.CC_ROP ? Number(b.mrpData.ReorderPoint) !== b.CC_ROP : b.ROP_DEVIATION;
            if (checkA > checkB) return -1;
            if (checkA < checkB) return 1;
            return 0;
          },
        }],
      } : { width: '0px' }),
    (!showRequisitions
      ? {
        children: [{
          title: 'SAP Deviation',
          render: (v) => {
            if (v.plannedOrderData) {
              const mrpIotaDiff = Number(v.plannedOrderData.TotalPlannedQuantity) !== Number(v.CC_PLANNED_ORDER_QTY);
              const iotaSapDiff = Number(v.plannedOrderData.AvailableStock) !== Number(v.AVAIL_STOCK);

              if (mrpIotaDiff || iotaSapDiff) {
                return (
                  <Tooltip title={(
                    <div style={{
                      display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '16px', maxWidth: '500px',
                    }}
                    >
                      {iotaSapDiff && (
                      <div className={styles.deviation}>
                        <div style={{ fontWeight: 'bold', color: '#ff0000' }}>Available stock</div>
                        <div className={styles.deviationValue}>
                          <div>IOTA:</div>
                          <div style={{ fontWeight: 'bold' }}>{v.AVAIL_STOCK}</div>
                        </div>
                        <div className={styles.deviationValue}>
                          <div>SAP:</div>
                          <div style={{ fontWeight: 'bold' }}>{Number(v.plannedOrderData.AvailableStock)}</div>
                        </div>
                      </div>
                      )}
                      {mrpIotaDiff && (
                      <div className={styles.deviation}>
                        <div style={{ fontWeight: 'bold', color: '#ff0000' }}>Pl Ord Qty</div>
                        <div className={styles.deviationValue}>
                          <div>IOTA</div>
                          <div style={{ fontWeight: 'bold' }}>{v.CC_PLANNED_ORDER_QTY}</div>
                        </div>
                        <div className={styles.deviationValue}>
                          <div>SAP:</div>
                          <div style={{ fontWeight: 'bold' }}>{v.plannedOrderData.TotalPlannedQuantity}</div>
                        </div>
                      </div>
                      )}

                    </div>
  )}
                  >
                    <CloseOutlined style={{ color: '#ff0000' }} />
                  </Tooltip>
                );
              }
            }
            return '';
          },
          width: '80px',
          sorter: (a, b) => {
            const mrpIotaDiff = Number(a.plannedOrderData?.TotalPlannedQuantity) !== a.CC_PLANNED_ORDER_QTY;
            const mrpSapDiff = Number(a.plannedOrderData?.TotalPlannedQuantity) !== Number(a.plannedOrderData?.AvailableStock);
            const iotaSapDiff = Number(a.plannedOrderData?.AvailableStock) !== a.CC_PLANNED_ORDER_QTY;

            const mrpIotaDiffB = Number(b.plannedOrderData?.TotalPlannedQuantity) !== b.CC_PLANNED_ORDER_QTY;
            const mrpSapDiffB = Number(b.plannedOrderData?.TotalPlannedQuantity) !== Number(b.plannedOrderData?.AvailableStock);
            const iotaSapDiffB = Number(b.plannedOrderData?.AvailableStock) !== b.CC_PLANNED_ORDER_QTY;

            const hasDiff = mrpIotaDiff || mrpSapDiff || iotaSapDiff;
            const hasDiffB = mrpIotaDiffB || mrpSapDiffB || iotaSapDiffB;

            if (hasDiff > hasDiffB) return -1;
            if (hasDiff < hasDiffB) return 1;
            return 0;
          },
        }],
      } : { width: '0px' }),
    (!showRequisitions
      ? {
        children: [{
          title: 'PL.Ord missing',
          render: (v) => (!v.isValid ? (
            <Tooltip title="Planned order does not exist">
              <CloseOutlined style={{ color: '#ff0000' }} />
              {' '}
            </Tooltip>
          ) : ''),
          width: '80px',
          sorter: (a, b) => {
            if (a.isValid > b.isValid) return -1;
            if (a.isValid < b.isValid) return 1;
            return 0;
          },
        }],
      } : { width: '0px' }),
    (showRequisitions
      ? {
        title: '',
        children: [{
          title: 'Requisition Number',
          render: (v) => {
            if (!v.requisitionNumber) return '';
            return (
              <div style={{ width: '100%', textAlign: 'right' }}>
                <a target="_blank" href={`https://p03web.statoil.no/sap/bc/gui/sap/its/webgui?~transaction=*MMPURPAMEREQ%20P_BANFN=${v.requisitionNumber}`} rel="noreferrer">{v.requisitionNumber}</a>
              </div>
            );
          },
          sorter: (a, b) => {
            if (a.requisitionNumber > b.requisitionNumber) return -1;
            if (a.requisitionNumber < b.requisitionNumber) return 1;
            return 0;
          },
          width: '120px',
        }],
      }
      : { width: '0px' }),
    {
      children: [{
        title: 'MD04',
        render: (v) => {
          const link = (p, m) => `${SAP_URL}sap/bc/gui/sap/its/webgui?sap-client=505&sap-language=EN&~transaction=*MD04%20RM61R-MATNR=${m};RM61R-WERKS=${p}`;
          const url = link(v.PLANT_FACILITY_SAP_ID, v.MATERIAL_TYPE_SAP_ID.slice(-8));
          return (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ width: '100%', height: '100%' }}
            >
              <a
                target="_blank"
                href={url}
                rel="noreferrer"
              >
                MD04
              </a>
            </div>
          );
        },
        width: '70px',
      }],
    },
  ];

  return (
    <div>
      <DraggableModal
        handle=".handle"
        title="Planned Order"
        visible={isVisible}
        className="plannedOrderModal"
        onCancel={() => close(false)}
        noFooter
        key={showRequisitions ? 'requisitions' : 'plannedOrders'}
        content={(
          <Table
            className={styles.fixedBinTable}
            rowClassName={getRowClassName}
            columns={columns}
            showSorterTooltip={false}
            dataSource={dataSource}
            pagination={false}
            size="small"
            scroll={{ x: 700, y: 500 }}
            loading={loading}
            footer={() => (
              <div className={styles.buttons}>
                {showRequisitions && (
                <>
                  <Button
                    onClick={() => {
                      close();
                    }}
                  >
                    Close
                  </Button>
                </>
                )}
                {!showRequisitions && (
                <>
                  <Button
                    onClick={() => {
                      setDeliveryDateToReqPostDate();
                    }}
                  >
                    Set Del. Date. Pur. Req. = Req. Post. Date.
                  </Button>
                  <Button
                    onClick={() => {
                      populateAllMaterials();
                    }}
                    disabled={!reGoodDate.test(populateAll?.deliveryDate) && !populateAll.qty && !reGoodDate.test(populateAll.reqPostDate) && !populateAll.grDays}
                  >
                    Populate all
                  </Button>
                  <Button
                    onClick={() => {
                      reset();
                    }}
                  >
                    Reset all changes
                  </Button>
                  {(hasValidData() && !showRequisitions) ? (
                    <Button
                      type="primary"
                      onClick={() => { submitPlannedOrders(); }}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      disabled
                    >
                      Submit
                    </Button>
                  )}
                </>
                )}
              </div>
            )}
          />
)}
      />

    </div>
  );
};

export default PlannedOrderModal;
