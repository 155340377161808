/* eslint-disable arrow-body-style */
import React, {
  useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, Row, Typography, Input, Divider, Empty,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import InfoModal from '../../../../Common/InfoModal';
import allActions from '../../../../../actions/index';
import * as loadingKeys from '../../../../../actions/MaterialDetailsDomain/LoadingKeys';
import { COMMENT_TYPE } from '../../../../Common/GlobalConstants';
import { getMaterial } from '../../../../../actions/MaterialDetailsDomain/ActionTypes';

const { Paragraph } = Typography;
const { TextArea } = Input;

const ExpandedLongTextModal = ({
  expanded, type, longText, close, authorizedToEdit, data,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authState?.user);
  const { plant, material } = useParams();
  const [infoTitle, setInfoTitle] = useState('');
  const [infoContent, setInfoContent] = useState(<></>);
  const [editing, setEditing] = useState(false);
  const [input, setInput] = useState('');

  const discardChanges = () => {
    setInput('');
    setEditing(false);
  };

  const submit = (textType) => {
    if (!plant || !material) return;
    if (textType === 'Pl_Ord_Txt') {
      dispatch(allActions.MaterialDetailsActions.setLoading(loadingKeys.mrp));
      dispatch(allActions.SapAPIActions.addPlannedOrderText(plant, material, input));
    } else if (textType === 'material_memo') {
      dispatch(allActions.MaterialDetailsActions.setLoading(loadingKeys.mrp));
      dispatch(allActions.SapAPIActions.addMaterialMemo(plant, material, input));
    } else if (textType === COMMENT_TYPE.WAREHOUSE || textType === COMMENT_TYPE.IOTA) {
      dispatch(allActions.MaterialDetailsActions.setLoading(getMaterial));
      dispatch(allActions.MaterialDetailsActions.addComment(
        user?.uniqueId, user?.account?.username, user?.account?.name, plant, material, input, type,
      ));
    }
    close();
  };

  const typeDisplay = {
    material_memo: 'Material memo',
    POtext: 'PO Text',
    Pl_Ord_Txt: 'Planned Order Text',
    warehouse: 'Warehouse Memo',
    iota: 'IOTA Memo',
  };

  useEffect(() => {
    if (type) {
      let defaultValue;
      if (type === COMMENT_TYPE.WAREHOUSE) {
        defaultValue = data.warehouseComment;
      } else if (type === COMMENT_TYPE.IOTA) {
        defaultValue = data.iotaComment;
      } else {
        defaultValue = longText;
      }
      setInput(defaultValue);
      setInfoTitle(typeDisplay[type]);
      setInfoContent(
        <Row style={{ width: '100%' }}>
          {editing && (
            <>
              <TextArea
                style={{ width: '98%' }}
                defaultValue={defaultValue}
                placeholder="New memo.."
                rows={3}
                showCount
                maxLength={15000}
                onChange={(e) => setInput(e.target.value)}
              />
              <Divider />
            </>
          )}
          <Paragraph style={{ minWidth: 400, whiteSpace: 'pre-line' }}>
            {defaultValue || (!editing ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ fontSize: '12px' }} description="No comments found" /> : '')}
          </Paragraph>
        </Row>,
      );
    }
    if (!expanded) {
      setInfoContent(<></>);
    }
  }, [longText, editing, type, expanded]);

  const getButtons = (textType) => {
    if (editing) {
      return [
        <Button key={1} onClick={() => submit(textType)}>
          Submit
        </Button>,
        <Button key={2} onClick={() => discardChanges(true)}>
          Discard
        </Button>,
      ];
    }
    if (authorizedToEdit) return [<Button key={1} onClick={() => setEditing(true)}>Edit</Button>];
    return [];
  };

  return (
    <InfoModal
      content={infoContent}
      title={infoTitle}
      visible={expanded}
      onCancel={close}
      footer={type === 'material_memo' || type === 'Pl_Ord_Txt' || type === COMMENT_TYPE.WAREHOUSE || type === COMMENT_TYPE.IOTA ? getButtons(type) : undefined}
      maxWidth="34vw"
    />
  );
};

export default ExpandedLongTextModal;

ExpandedLongTextModal.defaultProps = {
  longText: '',
  authorizedToEdit: false,
};

ExpandedLongTextModal.propTypes = {
  expanded: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  longText: PropTypes.string,
  close: PropTypes.func.isRequired,
  authorizedToEdit: PropTypes.bool,
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};
